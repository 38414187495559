import React, { FC, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LinkType } from "../../types";
import {
  createMarkup,
  useBackgroundColor,
  useResponsiveType,
} from "../../utils";
import Container from "../container/Container";
import Stack from "../stack/Stack";
import { LogoInverse } from "../svg/svgs/LogoInverse";
import { LinkTo } from "../linkTo/LinkTo";
import { FSCSnverse } from "../svg/svgs/FSCSInverse";
import { Divider } from "../divider/Divider";
import { Typography } from "../typography/Typography";
import { ChevronDown, LinkedIn, Facebook, X } from "../icon";
import { useDataLayerPush } from "../../hooks/useDataLayerPush";
import Image from "../image/Image";

// Interface

export interface FooterProps {
  navigationColumns?: FooterMenuProps[];
  smallText?: string;
  socialLinks?: SocialLinks;
  logos?: any[];
}

type SocialLinks = {
  facebook: string;
  x: string;
  linkedIn: string;
};

export interface FooterMenuProps {
  heading?: string;
  links?: LinkType[];
}

interface StyledNavLinksProps {
  isOpen: boolean;
}

// Styles

const StyledFooter = styled.footer`
  ${({ theme: { space } }) => css`
    ${useBackgroundColor("darkBlue")};
    padding: ${space.fluidLargeToXxLarge} 0;
  `};
`;

const StyledBrandBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { media } }) => css`
    transition: all 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
    svg {
      width: 144px;
      height: auto;
    }

    @media (min-width: ${media.large}) {
      svg {
        width: 176px;
        height: auto;
      }
    }
  `}
`;

const StyledFscsLogo = styled(LinkTo)`
  ${({ theme: { media } }) => css`
    svg {
      width: 44px;
      height: 48px;
    }

    @media (min-width: ${media.large}) {
      svg {
        width: 53px;
        height: auto;
      }
    }
  `}
`;

const StyledNavigation = styled.nav`
  ${({ theme: { space, media, maxMedia } }) => css`
    display: grid;

    a {
      text-decoration: none;
      ${useResponsiveType("bodyBold")}

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: ${maxMedia.large}) {
      gap: ${space.small};
    }

    @media (min-width: ${media.large}) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      button {
        cursor: default;
      }
    }
  `};
`;

const StyledLogos = styled.div`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

const StyledSocialLinks = styled.ul`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    gap: ${space.xSmall};

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    a {
      display: inline-block;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

const StyledNavToggle = styled.button<StyledNavLinksProps>`
  ${({ theme: { space, colors, media, maxMedia }, isOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      transition: transform 0.3s;
      transform: ${isOpen ? "rotate(180deg)" : "rotate(0)"};
    }

    @media (min-width: ${media.large}) {
      svg {
        display: none;
      }
    }
  `};
`;

const StyledNavLinks = styled(Stack)<StyledNavLinksProps>`
  ${({ theme: { maxMedia }, isOpen }) => css`
    @media (max-width: ${maxMedia.large}) {
      display: ${isOpen ? "block" : "none"};
    }
  `};
`;

// JSX

export const FooterMenu = ({ heading, links }: FooterMenuProps) => {
  const { dataLayerPush } = useDataLayerPush();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);

    dataLayerPush({
      event: "footer_menu_toggle",
      footer_menu: heading,
    });
  };

  return (
    <Stack gap="xSmall">
      {heading && (
        <StyledNavToggle onClick={toggleMenu} isOpen={isMenuOpen}>
          <Typography component="span" variant="headingXSmall">
            {heading}
          </Typography>
          <ChevronDown />
        </StyledNavToggle>
      )}

      <StyledNavLinks gap="xSmall" as="ul" isOpen={isMenuOpen}>
        {links?.map(({ label, url, target }, index) => (
          <li key={index}>
            <LinkTo
              to={url}
              target={target}
              onClick={() => {
                setMenuOpen(false);
                dataLayerPush({
                  event: "footer_link_click",
                  footer_link: label,
                });
              }}
            >
              {label}
            </LinkTo>
          </li>
        ))}
      </StyledNavLinks>
    </Stack>
  );
};

export const Footer: FC<FooterProps> = ({
  navigationColumns,
  smallText,
  socialLinks,
  logos,
}) => {
  const { dataLayerPush } = useDataLayerPush();

  const socials = [
    {
      label: "Facebook",
      url: socialLinks?.facebook,
      icon: <Facebook size="large" />,
    },
    {
      label: "X",
      url: socialLinks?.x,
      icon: <X size="large" />,
    },
    {
      label: "LinkedIn",
      url: socialLinks?.linkedIn,
      icon: <LinkedIn size="large" />,
    },
  ];
  return (
    <StyledFooter>
      <Container>
        <Stack gap="fluidLargeToXxLarge">
          <Stack gap="medium">
            <StyledBrandBar>
              <StyledLogo to="/" title="Zempler home">
                <LogoInverse />
              </StyledLogo>
              {logos?.map((topLogo, index) => (
                <Image
                  key={index}
                  src={topLogo.image?.localFile}
                  alt={topLogo.image?.altText}
                />
              ))}
              {/* <StyledFscsLogo
                to="https://www.fscs.org.uk/"
                target="_blank"
                title="FSCS protected"q
              >
                <FSCSnverse />
              </StyledFscsLogo> */}
            </StyledBrandBar>
            <Divider />
            <StyledNavigation>
              {navigationColumns?.map((navigationColumn, index) => (
                <FooterMenu key={index} {...navigationColumn} />
              ))}
            </StyledNavigation>
          </Stack>
          <Stack gap="medium">
            <Divider />
            <StyledLogos>
              <StyledSocialLinks>
                {socials.map(({ label, url, icon }, index) => {
                  if (!url) {
                    return null;
                  }
                  return (
                    <li key={index}>
                      <LinkTo
                        to={url}
                        target="_blank"
                        title={label}
                        onClick={() =>
                          dataLayerPush({
                            event: "social_link_click",
                            social_link: label,
                          })
                        }
                      >
                        {icon}
                      </LinkTo>
                    </li>
                  );
                })}
              </StyledSocialLinks>
              <Typography component="p" variant="bodyBold">
                © {new Date().getFullYear()} Zempler Bank
              </Typography>
            </StyledLogos>
            <Divider />
          </Stack>
          {smallText && (
            <Typography variant="bodySmall" component="div">
              <Stack gap="xSmall">{createMarkup(smallText)}</Stack>
            </Typography>
          )}
        </Stack>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
